import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import { Link } from "react-router-dom";
import Spinner from "./Spinner";
import axios from "axios";

import excellence_logo from '../../assets/images/mccoy-excellence-logo.jpg'

const HeroSection = () => {
  const [slidIndex, setSlidIndex] = useState(0);

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    beforeChange: (current, next) => setSlidIndex(next),
  };
  const sliderRef = useRef();
  const handleOnClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  const [isLoading, setLoading] = useState(true);
  const [allSliders, setAllSliders] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/mainpage"
        );
        setAllSliders(data.banner_section);
        console.log("data.banner_section", data.banner_section)
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <div className="position-relative">
        <Slider ref={sliderRef} {...settings}>
          {allSliders.map((item, index) => {
            return (
              <div
                className="carousel-slide bg-overlay"
                aria-hidden="true"
                key={index}
              >
                <img
                  src={`https://mccoy-usa.com/admin/assets/front/img/sliders/${item.image}`}
                  alt="sliderImage"
                />
                <div className="carousel-content">
                  <div className="row justify-content-between flex-md-row flex-column-reverse">
                    <div className="col-md-9 pe-md-5">
                      <h2>{item.title}</h2>
                      <p>{item.text}</p>

                      <Link to="/warp-preparation" className="carousel-content-btn">
                        {item.button_text}
                      </Link>
                      <Link to="/unrolling-creels" className="carousel-content-btn">
                        {item.button_url}
                      </Link>
                    </div>
                    <div className="col-md-3 px-md-0 px-5 mb-md-0 mb-5">
                      <div className="excellence_logo ">
                        <img src={excellence_logo} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>

        <div className="carousel-btns">
          <button
            onClick={(e) => handleOnClick(e.target.value)}
            value={0}
            className={`${slidIndex === 0 && "currSlide"}`}
            aria-label="slide button 1"
          ></button>

          <button
            onClick={(e) => handleOnClick(e.target.value)}
            value={1}
            className={`${slidIndex === 1 && "currSlide"}`}
            aria-label="slide button 2"
          ></button>

          <button
            onClick={(e) => handleOnClick(e.target.value)}
            value={2}
            className={`${slidIndex === 2 && "currSlide"}`}
            aria-label="slide button 3"
          ></button>

          <button
            onClick={(e) => handleOnClick(e.target.value)}
            value={3}
            className={`${slidIndex === 3 && "currSlide"}`}
            aria-label="slide button 4"
          ></button>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
