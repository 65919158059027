import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

// css
import "../../assets/css/ContactUs.css";
import axios from "axios";
import Spinner from "../HomePage/Spinner";
import { Helmet } from "react-helmet";
import TopHeading from "../TopHeading";

const ContactParts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoading, setLoading] = useState(true);
  const [parts, setParts] = useState([]);
  const [ctaSection, setCtaSection] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/parts"
        );
        setParts(data.parts);
        setCtaSection(data.cta_section);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <Helmet>
        <title>McCoy Textile Equipment - Built for Excellence</title>
        <meta name="description" Content="McCoy textile equipment is built for excellence, offering high-performance solutions designed for precision, durability, and optimized textile production efficiency" />
        <meta name="keywords" content="McCoy Textile Equipment" />
      </Helmet>

      <TopHeading
        title={"Advanced McCoy Textile Equipment"}
        name={"Parts Accessories"}
      />

      <section className="pb-5 pt-md-3 bg-light">
        <div className="container-box py-5">
          {parts.map((item, index) => {
            return (
              <div className="row justify-content-between" key={index}>
                <div className="col-md-5 d-md-block d-none">
                  <img src={`https://mccoy-usa.com/admin/assets/front/img/${item.part_img}`} className="w-100" alt="" />
                </div>

                <div className="col-md-7 ps-md-4 pe-md-0">
                  <h2 className="text-dark-blue h2 mb-3">{item.part_title}</h2>

                  <h5 className="fw-bold text-dark-blue h5">
                   {item.part_subtitle}
                  </h5>

                  <p className="paragraph text-justify">
                    {item.part_overview}
                  </p>
                  <h5 className="h5 text-dark-blue fw-bold">
                    {item.part_secondtitle}
                  </h5>
                  <p className="paragraph">{item.part_number}</p>

                  <h5 className="h5 text-dark-blue fw-bold">OR</h5>

                  <p className="paragraph">{item.part_email}</p>
                  <br />

                  <div className="">
                    <a
                      href={`mailto:${item.part_btn_text}`}
                      target="_blank"
                      className="carousel-content-btn text-lowercase"
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="pe-3"
                      ></FontAwesomeIcon>
                      {item.part_btn_text}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="py-5">
        <div className="container-box py-md-5 ">
          {ctaSection.map((item, index) => {
            return (
              <div className="row align-items-center" key={index}>
                <div className="col-md-8 pe-md-5">
                  <h2 className="h2 text-dark-blue">{item.partscta_title} </h2>
                  <h5 className="h5 py-2">{item.partscta_subtitle}</h5>

                  <p className="paragraph text-justify">
                   {item.partscta_overview}
                  </p>
                  <Link
                    to="/contact"
                    className="carousel-content-btn mt-2 d-inline-block"
                  >
                    {item.partscta_btntext}
                  </Link>
                </div>

                <div className="col-md-4 text-end d-md-block d-none">
                  <img src={`https://mccoy-usa.com/admin/assets/front/img/${item.partscta_img}`} alt="" className="w-100" />
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default ContactParts;
