import './App.css';
// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// router
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// css
import './assets/css/default/index.css';
// components
import Navbar from './components/HomePage/Navbar';
import { Footer } from './components/HomePage/Footer';

import Home from './components/HomePage/Home';
import About from './components/About/About';
import OurTeam from './components/About/OurTeam';
import EventDetails from './components/HomePage/EventDetails';
import ContactUs from './components/Contact/ContactUs';
import ContactSales from './components/Contact/ContactSales';
import ContactRepairService from './components/Contact/ContactRepairService';
import ContactParts from './components/Contact/ContactParts';
// Textile Page
import Creels from './components/Textile/Creels/Creels';
import CreelsDetails from './components/Textile/Creels/CreelsDetails';
import WarpPrepration from './components/Textile/WarpPreparation/WarpPrepration';
import Ancillary from './components/Textile/Ancillary/Ancillary';
import AncillaryDetails from './components/Textile/Ancillary/AncillaryDetails';
import Tensions from './components/Textile/Tensions/Tensions';
import TensionsDetails from './components/Textile/Tensions/TensionsDetails';

import CustomSolutions from './components/Composites/CustomSolutions/CustomSolutions';
import DrivenSystem from './components/Composites/DrivenSystem/DrivenSystem';
import UnrollingCreels from './components/Composites/UnrollingCreels/UnrollingCreels';
import UnrollingCreelsDetails from './components/Composites/UnrollingCreels/UnrollingCreelsDetails';
import WarpPreprationDetails from './components/Textile/WarpPreparation/WarpPreparationDetails';
import News from './components/News/News';

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/event-details" element={<EventDetails />} />
          <Route path="/event-details/:slug" element={<EventDetails />} />

          {/* ------------------------------------------------------------------- */}

          {/* Textile Page */}
          <Route path="/warp-preparation" element={<WarpPrepration />} />
          <Route path="/warp-preparation-details" element={<WarpPreprationDetails />} />

          <Route path="/creels" element={<Creels />} />
          <Route path="/creels-details" element={<CreelsDetails />} />

          <Route path="/tension-devices" element={<Tensions />} />
          <Route path="/tensions-details" element={<TensionsDetails />} />

          <Route path="/ancillarys" element={<Ancillary />} />
          <Route path="/ancillarys-details" element={<AncillaryDetails />} />

          {/* ------------------------------------------------------------------- */}

          {/* Composite Page */}
          <Route path="/unrolling-creels" element={<UnrollingCreels />} />
          <Route path="/unrolling-creels-details" element={<UnrollingCreelsDetails />} />

          <Route path="/composites/driven-systems" element={<DrivenSystem />} />

          <Route path="/composites/custom-solutions" element={<CustomSolutions />} />

          {/* ------------------------------------------------------------------- */}

          {/* about page */}
          <Route path="/about" element={<About />} />
          <Route path="/about/our-team" element={<OurTeam />} />

          {/* contact page */}
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/contact/request-a-quotes" element={<ContactSales />} />
          <Route path="/service" element={<ContactRepairService />} />
          <Route path="/parts-accessories" element={<ContactParts />} />

          {/* news page */}
          <Route path="/news" element={<News />} />

        </Routes>

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
